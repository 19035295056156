import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select-billable"
export default class extends Controller {
  static targets = ['edit', 'display']

  toggle(evt) {
    const selected = evt.target.selectedOptions[0]
    console.log('select-billable', selected)

    if (selected.dataset.edit == 'true') {
      this.editTarget.classList.remove('hidden')
      this.displayTarget.classList.add('hidden')

    } else {
      this.editTarget.classList.add('hidden')
      this.displayTarget.classList.remove('hidden')

      let text = ""
      if ( selected.dataset.billable == "N" ) text = "nicht"

      this.displayTarget.innerHTML =
        `Die erfassten Stunden zu diesem Projekt werden ${text} verrechnet.`
    }
  }
}
