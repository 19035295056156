import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="status"
export default class extends Controller {
  static targets = ['changeable']
  static values = {
    id: Number
  }
  static last_id = null;

  change(event) {
    if( this.last_id == null ) { this.last_id = event.params.id }
    this.changeableTarget.value = event.params.id

    document
      .querySelector(`#status-${this.last_id} div`)
      .classList.remove('border-2', 'border-gray-200')

    document
      .querySelector(`#status-${event.params.id} div`)
      .classList.add('border-2', 'border-gray-400')

    this.last_id = event.params.id
  }
}
