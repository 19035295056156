// https://stimulus-use.github.io/stimulus-use/#/use-click-outside

import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

// Connects to data-controller="menu"
export default class extends Controller {
  static targets = ['toggleable']

  connect() {
    useClickOutside(this)
  }

  toggle() {
    this.toggleableTarget.classList.toggle("invisible")
  }

  close() {
    this.toggleableTarget.classList.add("invisible")
  }
}
