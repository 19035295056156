// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
// ./bin/rails generate stimulus controllerName

import { application } from "./application"

import NestedForm from "stimulus-rails-nested-form"

import MessageController from "./message_controller"
application.register("message", MessageController)

import DropdownController from "./dropdown_controller"
application.register("dropdown", DropdownController)

import MenuController from "./menu_controller"
application.register("menu", MenuController)

import StatusController from "./status_controller"
application.register("status", StatusController)

import SelectBillable from "./select_billable_controller"
application.register("select-billable", SelectBillable)

application.register("nested-form", NestedForm)
